/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import store from './store/index'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/inconfont/iconfont.css'
import FastClick from 'fastclick'

FastClick.attach(document.body)

Vue.config.productionTip = false
Vue.use(ElementUI);

const VUE_APP_VERSION = require('../package.json').version
const vers = window.localStorage.getItem("Version");
if (VUE_APP_VERSION != vers) {
  localStorage.clear()
  window.localStorage.setItem("Version", VUE_APP_VERSION);
  location.reload()
}



new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app');
