/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  //数据，相当于data
  state: {
    uid: '',
    // userinfo: null
  },
  getters: {
    // uid(state) {
    //   // console.log(state)
    //   return (keyword) => {
    //     if (keyword) {
    //       let result = state.uid.filter((item) => {
    //         return item.key == keyword;
    //       })
    //       let resultData = result[0].uid.filter((item) => {
    //         return item.dictType == "node";
    //       })
    //       return resultData;
    //     } else {
    //       return state.uid
    //     }
    //   }
    // },
    // currinfo(state) {
    //   // console.log(a,b)
    //   console.log(state.uid)
    //   // params = state.userinfo
    //   // console.log(params)

    // }
    // currinfo: state => state.userinfo,
  },
  //里面定义方法，操作state方发
  mutations: {
    // uid(state, params) {
    //   // console.log(state,params)
    //   state.uid = params;
    // },
    // setUser(state, user) {
    //   state.userinfo = user
    // },

  },
  // 操作异步操作mutation
  actions: {

  },
  modules: {

  },
})
