<template>
  <div id="mains">
    <div v-show="isShow=='true'" style="position: relative; margin-top: 20px">
      <!-- <img src="../static/logo/servicelogo.png" alt="" />
      <b class="logotext"
        >易快服务商</b
      > -->
     
    </div>
    <div v-show="isShow!='true'" style="position: relative; margin-top: 20px">
      <!-- <img  src="../static/logo/index-logo1.png" alt="" />
      <b class="logotext" style="color: #16253E;"
        >易快代理商</b
      > -->
     
    </div>

    <div style="display: flex; line-height: 22px">
      <p
        style="
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #212533;
        "
      >
        {{ user.companyname }}
      </p>
      <br />
      <a  style="position: absolute;
    right: 28px;
    top: 50px;color: black;" @click="equit">退出登录</a>
    </div>
  </div>
</template>

<script>
import storage from "@/store/storage";
import { msgAPI } from "@/api";

export default {
  name: "main-header",
  props: {
    String,
  },

  data() {
    return {
      user: "",
      type:'',
      isShow:false
    };
  },
  mounted() {
    // console.log(this.$route.query.flag)
    // this.type=Base64.decode(this.$route.query.flag);
   
    var uid = JSON.parse(storage.get("uid"));
    this.type = JSON.parse(storage.get("flag"));

    if (!uid) {
      this.$router.push({ path: "/" });
    }
    if( this.type=='1'){
      this.isShow='true'
    }else if( this.type =='2'){
      this.isShow='flase'
    }
    msgAPI({ uid: uid }).then((res) => {
      if (res.data.code == "200") {
        this.user = res.data.userinfo;
      }
    });
  },
  methods:{
    equit(){
      this.$router.push(
          {
            name: "quitI",
            path: `/quit`,
          },
          () => {}
        );
    }
  }
};
</script>

<style>
#mains {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.logotext {
  width: 106px;
  height: 20px;
  font-size: 19px;
  font-family: FZHei-B01S;
  font-weight: 550;
  color: #16253e;
  position: absolute;
  top: 2px;
}
</style>