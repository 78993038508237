<template>
  <div id="main">
    <el-row class="tac">
      <el-col :span="20">
        <el-menu class="el-menu-vertical-demo" :default-openeds="openeds" style="border-right: 0px;">
          <el-menu-item
            index="1"
            @click="handleOpen(0, isShow)"
            :class="{ active: 0 == active1 }"
          >
            <div style="margin-left: -24px;width: 200px;">
              <div>
                <i class="icon-entity10">
              <img
                src="../static/index_slices/icon-homehui.png"
                style="width: 20px; height: 20px"
              />
            </i>
            <span class="title">首页</span>
             </div>
          </div>

          </el-menu-item>
          <el-submenu index="5" :style="style">
            <template slot="title">
              <el-menu-item style="width: 200px;padding-left: 25px;line-height: 56px;">
            <i class="icon-entity10">
                <img
                  src="../static/index_slices/icon-qianyueguanlihui.png"
                  style="width: 20px; height: 20px"
                />
              </i>
              <span class="title" style="">签约管理</span>
          </el-menu-item>
            </template>
            <el-menu-item index="5-1" @click="handleOpen(5,isShow)"
            :class="{ active: 5 == active1 }">
           <div style="margin-left: -14px;">
            <i class="icon-entity10">
                  <img
                    src="../static/index_slices/icon-nosignhui.png"
                    style="width: 20px; height: 20px"
                  />
                </i>
              <span class="title">待签约</span>
           </div>
            </el-menu-item>
            <el-menu-item index="5-2"  @click="handleOpen(2, isShow)"
                :class="{ active: 2 == active1 }">
                <i class="icon-entity10">
                  <img
                    src="../static/index_slices/icon-qianyuejiluhui.png"
                    style="width: 20px; height: 20px"
                  />
                </i>
              <span class="title">签约记录</span>
            </el-menu-item>
            <el-menu-item index="5-3"  @click="handleOpen(10, isShow)"
                :class="{ active: 10 == active1 }">
                <i class="icon-entity10">
                  <img
                    src="../static/index_slices/icon-wodeqianyuehui.png"
                    style="width: 20px; height: 20px"
                  />
                </i>
              <span class="title">我的签约</span>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <el-menu-item style="width: 200px;padding-left: 25px;line-height: 56px;">
            <i class="icon-entity10">
                <img
                  src="../static/index_slices/icon-markhui.png"
                  style="width: 20px; height: 20px"
                />
              </i>
              <span class="title" style="">数据中心</span>
          </el-menu-item>
            </template>
           
            <!-- <el-menu-item-group> -->
              <el-menu-item
                index="2-1"
                @click="handleOpen(1, isShow)"
                :class="{ active: 1 == active1 }"
              >
                <i class="icon-entity10">
                  <img
                    src="../static/index_slices/icon-zijinhui.png"
                    style="width: 20px; height: 20px"
                  />
                </i>
                <span class="title">资金明细</span>
                <!-- <i class="iconfont el-sevicezijinmingxi"></i>  
                资金明细 -->
              </el-menu-item>
            <!-- </el-menu-item-group> -->
          </el-submenu>
          <el-submenu index="3" >
              <template slot="title">
            <el-menu-item style="width: 200px;padding-left: 25px;line-height: 56px;">

              <i class="icon-entity10">
                <img
                  src="../static/index_slices/icon-zijinzhanghuhui.png"
                  style="width: 20px; height: 20px"
                />
              </i>
              <span class="title" style="">资金账户</span>
              <!-- <i class="iconfont el-sevicezijinzhanghu"></i>
             <a style="font-weight: 600;">资金账户</a> -->
            </el-menu-item>

            </template>
          
              <el-menu-item
                index="3-1"
                @click="handleOpen(8, isShow)"
                :class="{ active: 8 == active1 }"
                :style="style1"
              >
                <i class="icon-entity10">
                  <img
                    src="../static/index_slices/icon-jiesuantixianhui.png"
                    style="width: 20px; height: 20px"
                  />
                </i>
                <span class="title">结算提现</span>
                <!-- <i class="iconfont el-sevicejiesuan"></i>
                结算提现 -->
              </el-menu-item>
              <el-menu-item
                index="3-2"
                @click="handleOpen(7, isShow)"
                :class="{ active: 7 == active1 }"
              >
                <i class="icon-entity10">
                  <img
                    src="../static/index_slices/icon-duixianjiluhui.png"
                    style="width: 20px; height: 20px"
                  />
                </i>
                <span class="title">兑现记录</span>
                <!-- <i class="iconfont el-seviceduihuanjilu"></i>
                兑现记录 -->
              </el-menu-item>
              <!-- <el-menu-item
                index="3-3"
                @click="handleOpen(2,isShow)"
                :class="{ active: 2 == active1 }"
              >
                <i class="iconfont el-seviceic_exchanged"></i>
                我要转让
              </el-menu-item> -->
              <el-menu-item
                index="3-4"
                @click="handleOpen(9, isShow)"
                :class="{ active: 9 == active1 }"
              >
                <i class="icon-entity10">
                  <img
                    src="../static/index_slices/icon-jiesuanzhanghuhui.png"
                    style="width: 20px; height: 20px"
                  />
                </i>
                <span class="title" >结算账户</span>
                <!-- <i class="iconfont el-sevicejiesuan1"></i>
                结算账户 -->
              </el-menu-item>
           
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <!-- <i class="iconfont el-sevicejichuxinxi"></i>
              <a style="font-weight: 600;">基础信息</a> -->
              <el-menu-item style="width: 200px;padding-left: 25px;line-height: 56px;">
                <i class="icon-entity10">
                <img
                  src="../static/index_slices/icon-jichuxinxihui.png"
                  style="width: 20px; height: 20px"
                />
              </i>
              <span class="title" style="">基础信息</span>
              </el-menu-item>
             
            </template>
            <!-- <el-menu-item-group> -->
              <el-menu-item
                index="4-3"
                @click="handleOpen(6, isShow)"
                :class="{ active: 6 == active1 }"
              >
                <!-- <i class="iconfont el-seviceshenfenxinxi"></i>
                身份信息 -->
                <i class="icon-entity10">
                  <img
                    src="../static/index_slices/icon-shenfenxinxihui.png"
                    style="width: 20px; height: 20px"
                  />
                </i>
                <span class="title">身份信息</span>
              </el-menu-item>
              <el-menu-item
                index="4-1"
                @click="handleOpen(3, isShow)"
                :class="{ active: 3 == active1 }"
              >
                <!-- <i class="iconfont el-seviceshimingrenzheng"></i>
                企业认证 -->
                <i class="icon-entity10">
                  <img
                    src="../static/index_slices/icon-shimingrenzhenghui.png"
                    style="width: 20px; height: 20px"
                  />
                </i>
                <span class="title">企业认证</span>
              </el-menu-item>
              <el-menu-item
                index="4-2"
                @click="handleOpen(4, isShow)"
                :class="{ active: 4 == active1 }"
              >
                <!-- <i class="iconfont el-sevicehetongguanli"></i>
                合同管理 -->
                <i class="icon-entity10">
                  <img
                    src="../static/index_slices/icon-hetongguanlihui.png"
                    style="width: 20px; height: 20px"
                  />
                </i>
                <span class="title">合同管理</span>
              </el-menu-item>
            <!-- </el-menu-item-group> -->
          </el-submenu>
          <!-- <el-menu-item
            index="5"
            @click="handleOpen(5)"
            :class="{ active: 5 == active1 }"
          >
            <i class="iconfont el-sevicetuichu"></i>
            <span slot="title">
              <a style="font-weight: 600;">退出&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
            </span>
          </el-menu-item> -->
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { bankAPI, withAPI } from "@/api/index";
import storage from "@/store/storage";
export default {
  name: "main-left",
  props: {
    String,
  },
  data() {
    return {
      active1: "0",
      style1: "display:none",
      openeds: ["1", "2", "3", "4","5"],
      isShow: false,  
      flag: "",
      stylebck:'background-image:url(../static/index_slices/indexbck.png)',
      style:''
    };
  },
  methods: {

    handleOpen(index, show) {

      if (show == "true") {
        this.flag = "1";
      } else {
        this.flag = "2";
      }
      // false代理商 true 服务商
      // console.log(show)
      if (index == "0") {
        if(this.$route.query.index=='0'){
        location.reload()
       }else{
        this.$router.push(  
          
          {
            name: "HomeView",
            path: `/home`,
            query: {
              index: index,
            },
          },
          () => {}
        );
       }
      } else if (index == "1") {
        if(this.$route.query.index=='1'){
        location.reload()
       }else{
        this.$router.push(
          {
            name: "fundsDetails",
            path: `/fundsDetails`,
            query: {
              index: index,
            },
          },
          () => {}
        );
       }
      } else if (index == "2") {
       if(this.$route.query.index=='2'){
        location.reload()
       }else{
        this.$router.push(
          {
            name: "management",
            path: '/management',
            force: true,
            query: {
              index: index,
            },
          },
          () => {
          }
        )
       }
        
      } else if (index == "3") {
        if(this.$route.query.index=='3'){
        location.reload()
       }else{
        this.$router.push(
          {
            name: "authentication",
            path: `/authentication`,
            query: {
              index: index,
            },
          },
          () => {}
        );}
      } else if (index == "4") {
        if(this.$route.query.index=='4'){
        location.reload()
       }else{
        this.$router.push(
          {
            name: "conTract",
            path: `/contract`,
            query: {
              index: index,
            },
          },
          () => {}
        );
       }
      } else if (index == "5") {
        if(this.$route.query.index=='5'){
        location.reload();
       }else{
        this.$router.push(
          {
            name: "pendingSigning",
            path: `/pendingSigning`,
            query: {
              index: index,
            },
          },
          () => {}
        );
       }
        
      } else if (index == "6") {
        if(this.$route.query.index=='6'){
        location.reload()
       }else{
        this.$router.push(
          {
            name: "userInfo",
            path: `/userinfo`,
            query: {
              index: index,
            },
          },
          () => {}
        );
       }
      } else if (index == "7") {
        if(this.$route.query.index=='7'){
        location.reload()
       }else{
        this.$router.push(
          {
            name: "exchangeRecord",
            path: `/exchangerecord`,
            query: {
              index: index,
            },
          },
          () => {}
        );}
      } else if (index == "8") {
        if(this.$route.query.index=='8'){
        location.reload()
       }else{
        this.$router.push(
          {
            name: "settLement",
            path: `/settLement`,
            query: {
              index: index,
            },
          },
          () => {}
        );
       }
      } else if (index == "9") {
        var uid = JSON.parse(storage.get("uid"));
        var data = {
          uid: uid,
          act: "getbank",
        };
        bankAPI(data).then((res) => {
          //  console.log(res.data.bank.type)
          if (res.data.bank.type) {
            // debugger
            if(this.$route.query.index=='9'){
        location.reload()
       }else{
            this.$router.push(
              {
                name: "withDraw",
                path: `/withdraw`,
                query: {
                  index: index,
                  p: "1",
                },
              },
              () => {}
            );}
          } else {
            if(this.$route.query.index=='9'){
        location.reload()
       }else{
            this.$router.push(
              {
                name: "withDraw",
                path: `/withdraw`,
                query: {
                  index: index,
                  index1: "1",
             },
              },
              () => {}
            );
          }}
        });
      }else if(index == "10") {
        if(this.$route.query.index=='10'){
        location.reload()
       }else{
        this.$router.push(
          {
            name: "mySigning",
            path: `/mySigning`,
            query: {
              index: index,
             
            },
          },
          () => {}
        );
      } }
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    time() {
      withAPI().then((a) => {
        if (a.data.code !== 605) {
          this.style1 = "display:block";
        } else {
          this.style1 = "display:none";
        }
      });
    },
  },
  created() {},
  mounted() {
    if (this.$route.query.index) {
      console.log(this.$route.query.index)
      this.active1 = this.$route.query.index;
    }
    this.time();
   this.flag = JSON.parse(storage.get("flag"))

    if (this.flag == "1") {
      this.isShow = "true";//服务商
      this.style='display:block'
    } else {
      this.isShow = "false";//代理商
      this.style='display:none'

    }
  },

};
</script>

<style scoped>
.tac {
  margin-top: 40px;
  background-color: transparent !important;
}
.el-menu-vertical-demo {
  background-color: transparent !important;
}
/* .el-menu{
  background: linear-gradient(90deg, #ADD1FD, #C1DDFD);
border-radius: 11px;;
} */
a {
  text-decoration: none;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #3d455f;
}
i {
  margin-right: 10px;
}
.active {
  background: linear-gradient(90deg, #DBEBFE, #E9F4FE) !important;
}
.title{
width: 27px;
height: 14px;
font-family: Source Han Sans CN;
font-weight: 400;
font-size: 14px;
color: #3D455F;
letter-spacing: 1px;
}
</style>