import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/index.vue'
// import storage from "@/store/storage";

// const originalPush = VueRouter.prototype.push;
// const originalReplace = VueRouter.prototype.replace;
// //push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject)
//     return originalPush.call(this, location, onResolve, onReject);
//   return originalPush.call(this, location).catch(err => err);
// };
// //replace
// VueRouter.prototype.replace = function push(location, onResolve, onReject) {
//     if (onResolve || onReject)
//         return originalReplace.call(this, location, onResolve, onReject);
//     return originalReplace.call(this, location).catch(err => err);
// };


// function isUserLoggedIn() {
//   // 这里应该是检查用户是否登录的逻辑，例如检查token或者其他方式
//   var uid = JSON.parse(storage.get("uid"));
//     if (!uid) {
//       this.$router.push({ path: "/" });
//       return false; 
//     }
//  // 假设用户未登录
// }
Vue.use(VueRouter)


const routes = [
  {
    path: '/home',
    name: 'HomeView',
    component: HomeView
  },
  {
    path:'/',
    name:'seviceLogin',
    component:()=>import('../views/seviceLogin.vue')
    },
  
  {
    path: '/fundsDetails',
    name: 'fundsDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/fundsDetails.vue')
  },
  {
    path: '/transfer',
    name: 'transfer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/tranSfer.vue')
  },
  {
    path: '/authentication',
    name: 'authentication',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/authenTication.vue')
  },
  {
    path: '/contract',
    name: 'conTract',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/conTract.vue')
  },
  {
    path: '/quit',
    name: 'quitI',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/quit.vue')
  },
  {
    path: '/userinfo',
    name: 'userInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/userInfo.vue')
  },
  {
    path:'/exchangerecord',
    name:'exchangeRecord',
    component: () => import(/* webpackChunkName: "about" */ '../views/exchangeRecord.vue')
  },
  {
    path:'/settLement',
    name:'settLement',
    component:()=>import('../views/settLement.vue')
  },{
    path:'/withdraw',
    name:'withDraw',
    component:()=>import('../views/withDraw.vue')
  },
  {
    path:'/pendingSigning',
    name:'pendingSigning',
    component:()=>import('../views/pendingSigning.vue'),
  },
  {
        path:'/pendingSigningchilren',
        name:'pendingSigningchilren',
        component:()=>import('../views/pendingSigningchilren.vue')
  },
  {
    path:'/management',
    name:'management',
    component:()=>import('../views/management.vue'),
   
  },
  // {
  //   path:'/Redirect',
  //   name:'Redirect',
  //   component:()=>import('../views/Redirect.vue'),
  // },
  {
    path:'/managementChildren',
    name:'managementChildren',
    component:()=>import('../views/managementChildren.vue')
  }
  ,{
    path:'/mySigning',
    name:'mySigning',
    component:()=>import('../views/mySigning.vue'),
   
  },
  {
    path:'/mySigningchildren',
    name:'mySigningchildren',
    component:()=>import('../views/mySigningchildren.vue')
  },
]



// router.beforeEach((to, from, next) => {
//   console.log(to,'to',from,'from')
//   if(to.fullPath==from.fullPath){
//     console.log('触发')
//     this.$router.go()
//   }
//   next();
// });

const router = new VueRouter({
  mode:'hash',
  routes
})
// router.beforeEach((to, from, next) => {
//   console.log(to.path,from.path,next)
//   if (to.path !== '/') {
//     next('/'); // 如果不是访问登录页且用户未登录，则重定向到登录页
//   } else {
//     next(); // 否则，正常跳转
//   }
// });
export default router
